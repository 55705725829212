import axios from '@/lib/drupal/api/axiosInstance';
import GamesModel, { DrupalGameData, ExtendedGameData, FeaturedGameData, GameData, mockGameSchema } from '@/lib/drupal/models/Games';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import { SearchCriteria } from '@/lib/hooks/useGamesByCriteria';
import { GamesByTaxonomyCriteria } from '@/lib/hooks/useGamesByTaxonomy';
import { mockGet } from '@/lib/util/mockRequest';
import { PagerData, formatPagerData } from '@/lib/util/pager';
import { pagingQueryString } from '@/lib/util/pagingQueryString';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';

export default class OnlineGamesModel {
  // #region All Genres

  static async searchAllGames (limit: number, offset: number, criteria: Omit<SearchCriteria, 'method'>, sortOrder: 'ASC' | 'DESC' = 'ASC'): Promise<{ games: ExtendedGameData[], pager: PagerData }> {
    let response: AxiosResponse;
    const sortBy = criteria.sortBy.replace('-', '');

    try {
      if (criteria.genre) {
        response = await axios.get(
          '/api/all-games/block/all-shockwave-games?' + 
          pagingQueryString(limit, offset) +
          `&tid=${criteria.genre}` +
          `&sort_by=${sortBy}` +
          `&sort_order=${sortOrder}`
        );
      } else {
        response = await axios.get(
          '/api/all-games/block/all-shockwave-games?' + 
          pagingQueryString(limit, offset) +
          `&sort_by=${sortBy}` +
          `&sort_order=${sortOrder}`
        );
      }
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get All Online Games', false, error);
      throw error;
    }

    return {
      games: response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game)),
      pager: formatPagerData(response.data.pager || { total_items: 0 })
    };
  }
  
  // #endregion



  // #region Per Genre

  static async searchGamesForGenre (limit: number, offset: number, criteria: Omit<GamesByTaxonomyCriteria, 'method'>): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/common/online/block/games-by-taxonomy/${criteria.sortBy}-games/${criteria.genre.uid}?` +
        pagingQueryString(limit, offset)
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Online Games (${criteria.genre.name})`, false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getTopGamesForGenre (genre: TaxonomyStub, limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/common/online/block/top-games/${genre.uid}?` +
        pagingQueryString(limit, offset)
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Top Online Games (${genre.name})`, false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getSliderGamesForGenre (genre: TaxonomyStub, limit: number, offset: number): Promise<FeaturedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/sw/api/common/online/block/slider/${genre.uid}?` +
        pagingQueryString(limit, offset)
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Slider Online Games (${genre.name})`, false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transformFeatured(game));
  }

  static async getFeaturedGamesForGenre (genre: TaxonomyStub, limit: number, offset: number): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/common/online/block/featured-games/${genre.uid}?` +
        pagingQueryString(limit, offset)
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Featured Online Games (${genre.name})`, false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  // #endregion
}
