import { Block, Button, ButtonLink, Buttons, Control, createComponent, Field, FieldType, Flex, For, Form, If, IntrinsicProps, useForm, useNotifications } from 'react-commons';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { formatDistance } from 'date-fns';

import { FullGameData } from '@/lib/drupal/models/Games';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useCommentList } from '@/lib/hooks/useCommentList';
import CommentModel, { CommentData } from '@/lib/drupal/models/Comments';
import { getZonedDate } from '@/lib/util/zonedTime';
import { useLoadingState } from '@/lib/hooks/useLoadingState';

import style from './index.module.scss';

interface DiscussionContainerProps extends IntrinsicProps {
  game: FullGameData
}

export const DiscussionContainer = createComponent<DiscussionContainerProps>('DiscussionContainer', { style }, function DiscussionContainer ({ className }, props) {
  const [ authState ] = useAuthStore();

  const {
    isLoading,
    hasReachedEnd,
    comments,
    fetchInitialComments,
    fetchNextComments,
  } = useCommentList(props.game.uid);

  useEffect(() => {
    fetchInitialComments();
  }, [ fetchInitialComments ]);

  const [ replyTo, setReplyTo ] = useState<CommentData | null>(null);

  const onClickReply = (comment: CommentData) => {
    setReplyTo(comment);
  };

  const onSubmit = () => {
    setReplyTo(null);
  };

  const onCancel = () => {
    setReplyTo(null);
  };

  return (
    <div className={className}>
      <Block>
        <LeaveCommentForm
          game={props.game}
          replyTo={replyTo}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Block>
      <Block>
        <Flex directionColumn gap0 container>
          {
            For(comments, (comment) => (
              <Comment 
                key={comment.uid}
                comment={comment}
                isLoggedIn={authState.ready && !!authState.user}
                onClickReply={() => onClickReply(comment)}
              />
            ))
          }
        </Flex>
      </Block>
      <Block>
        <Buttons centered>
          {
            If(!hasReachedEnd, () => (
              <Button medium rounded secondary loading={isLoading} onClick={fetchNextComments}>
                Load more
              </Button>
            )).Else(() => (
              <Button medium rounded secondary disabled>
                No more comments
              </Button>
            )).EndIf()
          }
        </Buttons>
      </Block>
    </div>
  );
});

export const CommentAvatar = createComponent('CommentAvatar', { style }, function CommentAvatar ({ className }, props) {
  return (
    <div className={className}>
      <Image
        width={46}
        height={46}
        alt=''
        src='/images/mockPlaceholder.png'
      />
    </div>
  );
});

interface CommentProps extends IntrinsicProps {
  comment: CommentData
  isLoggedIn: boolean
  onClickReply: () => void
}

export const Comment = createComponent<CommentProps>('Comment', { style }, function Comment ({ className }, props) {
  const formatDate = (date: string) => {
    return formatDistance(
      getZonedDate(props.comment.created), 
      getZonedDate(),
      {
        addSuffix: true,
      }
    );
  };

  return (
    <div className={className}>
      <Flex gap0 container>
        <div>
          <Image
            width={46}
            height={46}
            alt={`Avatar for ${props.comment.author.displayName}`}
            src={props.comment.author.avatarUrl}
            aria-hidden
          />
        </div>
        <div>
          <Flex directionColumn>
            <Flex tag='ul'>
              <li>
                <strong>{props.comment.author.displayName}</strong>
              </li>
              <li>{formatDate(props.comment.created)}</li>
              {
                If(props.isLoggedIn, () => (
                  <li><a onClick={props.onClickReply}>Reply</a></li>
                )).EndIf()
              }
            </Flex>
            <div className='--marSm2__t'>
              {props.comment.body}
            </div>
          </Flex>
        </div>
      </Flex>
    </div>
  );
});

interface LeaveCommentFormProps extends IntrinsicProps {
  game: FullGameData
  replyTo: CommentData | null
  onSubmit: () => void
  onCancel: () => void
}

interface LeaveCommentFormData {
  comment: string
}

const leaveCommentFormSchema = {
  comment: {
    value: '',
    schema: {
      type: FieldType.ANY,
      required: true,
    }
  }
};

export const LeaveCommentForm = createComponent<LeaveCommentFormProps>('LeaveCommentForm', { style }, function LeaveCommentForm ({ className }, props) {
  const textareaElRef = useRef<HTMLTextAreaElement>();
  const [ authState ] = useAuthStore();
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const form = useForm(leaveCommentFormSchema);
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();

  const onSubmit = async () => {
    if (!authState.ready || !authState.user) return;
    
    const { data, hasErrors } = form.validate<LeaveCommentFormData>();
    if (hasErrors) return;
    
    startLoading();

    try {
      await CommentModel.leaveComment(props.game.uid, 'daily', data.comment);
      form.resetForm();
    } catch {
      notify('There was an error submitting your comment. Please try again later.', 'error');
      finishLoading();
      return;
    }

    notify('Thanks for commenting!');
    finishLoading();
    props.onSubmit();
  };

  const onCancel = () => {
    form.resetForm();
    props.onCancel();
  };

  useEffect(() => {
    if (props.replyTo) {
      form.setField('comment', `@${props.replyTo.author.displayName} `);
      textareaElRef.current.focus();
    } else {
      form.setField('comment', '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.replyTo ]);

  return (
    <div className={className}>
      {
        If(authState.ready && authState.user, () => (
          <Form onSubmit={onSubmit}>
            <Flex container gap0 alignCenter>
              <div>
                <Image
                  width={46}
                  height={46}
                  alt='Your avatar'
                  src={authState.user.avatarUrl}
                  aria-hidden
                />
              </div>
              <Flex fit directionColumn>
                {
                  If(props.replyTo, () => (
                    <Flex tag='ul' className='--marSm2__b'>
                      <li>Replying to <strong>{props.replyTo.author.displayName}</strong></li>
                      <li><a onClick={props.onCancel}>Cancel</a></li>
                    </Flex>
                  )).EndIf()
                }
                <div>
                  <Field key='field_comment' className={className}>
                    <Control>
                      <textarea
                        key='comment'
                        ref={textareaElRef}
                        value={form.data[ 'comment' ]} 
                        placeholder={'Leave your comment.'}
                        disabled={isLoading}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => form.setField('comment', event.target.value)}
                        onFocus={() => form.resetErrors(true)}
                        onBlur={(event: React.ChangeEvent<HTMLTextAreaElement>) => form.validateField('comment', event.target.value)}
                      />
                    </Control>
                  </Field>
                </div>
                {
                  If(form.data.comment.length, () => (
                    <div className='--marSm2__t'>
                      <Buttons right>
                        <Button onClick={onCancel} small rounded>
                          Cancel
                        </Button>
                        <Button rounded small secondary submit>
                          Submit
                        </Button>
                      </Buttons>
                    </div>
                  )).EndIf()
                }
              </Flex>
            </Flex>
          </Form>
        )).Else(() => (
          <Flex alignCenter justifyCenter container gap0>
            <h6>Want to join the conversation?</h6>
            <ButtonLink href={'/account/register'} medium rounded secondary>
              Create Account
            </ButtonLink>
          </Flex>
        )).EndIf()
      }
    </div>
  );
});
