import React from 'react';
import { NextPageContext } from 'next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { ASPECT_4_3, Block, Button, ButtonLink, Container, createPage, Flex, For, If, Slot, Title, useModal } from 'react-commons';

import { displayPreroll } from '@/lib/util/displayPreroll';
import GamesModel, { DailyGameFields, ExtendedGameData, FullGameData, getDailyGameSrc } from '@/lib/drupal/models/Games';
import { useGenreBreadcrumbs } from '@/lib/hooks/useGameBreadcrumbs';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import onlineGenres from '@/lib/data/onlineGenres.json';
import downloadGenres from '@/lib/data/downloadGenres.json';
import DownloadGamesModel from '@/lib/drupal/models/DownloadGames';
import { GamesByTaxonomyCriteria, fetchGamesByTaxonomyTerm } from '@/lib/hooks/useGamesByTaxonomy';
import OnlineGamesModel from '@/lib/drupal/models/OnlineGames';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import { useMessageApi } from '@/lib/util/messageApi';
import { recentlyPlayedActions, useRecentlyPlayed } from '@/lib/stores/recentlyPlayed';
import { dailyFieldsToDate, useCurrentDay } from '@/lib/hooks/useDailyArchive';
import SwagModel from '@/lib/swag/models/Swag';
import { getZonedDate } from '@/lib/util/zonedTime';
import { getFieldsFromDateQuery } from '@/lib/util/gamePlayerTools';

import DailyGameLayout from '@/layouts/DailyGameLayout';

import Meta, { generateGamePageMetaData } from '@/components/Meta';
import GamePlayer from '@/components/GamePlayer';
import GamePlayerHeader from '@/components/GamePlayerHeader';
import DailyHero, { DailyHeroEmpty } from '@/components/DailyHero';
import { DailyGameTile, HeroPlayGameTile, MobileHeroGameTile } from '@/components/DailyGameTile';
import { CompactDailyGameTileContainer, HeroDailyGameTileContainer } from '@/components/DailyGameTileContainer';
import { WeeklyArchive, DailyArchive } from '@/components/gamePage/Archive';
import { LeaderboardContainer } from '@/components/gamePage/Leaderboards';
import { DiscussionContainer } from '@/components/gamePage/Discussion';
import ShareModal from '@/components/ShareModal';
import DownloadGameTile from '@/components/DownloadGameTile';
import GameRequirementsTile from '@/components/GameRequirementsTile';
import DailyGameGenreGrid from '@/components/DailyGameGenreGrid';
import DownloadsUpsellTile from '@/components/DownloadsUpsellTile';
import PerDevice, { isMobile } from '@/components/PerDevice';
import ArchivesUpsellTile from '@/components/ArchivesUpsellTile';
import DiscontinuedGame from '@/components/DiscontinuedGame';
import BonusGameTile from '@/components/BonusGameTile';
import { DailyArchiveUpsellTile } from '@/components/DailyArchiveUpsellTile';
import { DailyGameUpsellTile } from '@/components/DailyGamesUpsellTile';
import { WeeklyArchiveUpsellTile } from '@/components/WeeklyArchiveUpsellTile';
import { usePlaywirePrecontentVideo } from '@/components/playwire/Playwire';
import PlaywireUnit from '@/components/playwire/PlaywireUnit';

import style from './index.module.scss';
import PlaywireAside from '@/components/playwire/PlaywireAside';
import PlaywireCornerVideo from '@/components/playwire/PlaywireCornerVideo';

export function getDailyGameQuery (fields: DailyGameFields) {
  return `${fields.year}/${fields.month.toString().padStart(2, '0')}/${fields.day.toString().padStart(2, '0')}`;
}

type GamePageView = 'about' | 'play';
type PlayPanelVisible = false | 'ad';

interface GamePageProps {
  game: FullGameData
  slug: string
  genre: TaxonomyStub
  moreGamesCriteria: GamesByTaxonomyCriteria
  initialMoreGames: ExtendedGameData[]
  swagUUID: string | null
}

interface GamePageData {
}

export default createPage<GamePageProps>('GamePage', { style }, function GamePage ({ className }, props) {
  const { game } = props;

  const router = useRouter();
  const [ authState ] = useAuthStore();
  const [ swagUser, setSwagUser ] = useState(null);
  const [ isFullScreen, setFullScreen ] = useState(false);
  const [ view, setView ] = useState<GamePageView>('about');
  const [ panel, setPanel ] = useState<PlayPanelVisible>(false);
  const genreBreadcrumbs = useGenreBreadcrumbs(game.genres, game.isDownloadGame);
  const { openModal } = useModal();

  const metaProps = generateGamePageMetaData(game, genreBreadcrumbs);
  const [ fields, today ] = useCurrentDay();
  const gameSrc = getDailyGameSrc(game.embed.modernSrc || game.embed.src, fields);

  const [ , dispatchRecentGames ] = useRecentlyPlayed();

  const [ showAdFallback, setShowAdFallback ] = useState(false);

  const { 
    sendShareDialogClosed,
    sendDisplayAdComplete,
  } = useMessageApi({
    game,
    targetWindowRef: null,
    onToggleFullScreen () {
      let state;
      setFullScreen((fullScreen) => state = !fullScreen);
      return state;
    },
    onDisplayAd () {
      setPanel('ad');
    },
    onDisplayShareDialog () {
      openModal('shareGame', {
        async onClose () {
          sendShareDialogClosed();
        }
      });
    },
    onNavigateToGameLanding () {
      if(isMobile(true)) {
        router.push(`${game.href}/archive`);
      } else {
        router.push(game.href);
      }
    }
  });

  const onShowGame = async (showGame) => {
    showGame();
  };

  const showPrecontent = usePlaywirePrecontentVideo();

  const onShowPrecontent = async (showGame) => {
    if (authState.user?.isPremiumUser) {
      return showGame();
    }
    showPrecontent(showGame);
  };

  const onClickPlayGame = () => {
    router.push({
      pathname: '/gamelanding/' + props.slug,
      query: { 
        date: router.query.date || getDailyGameQuery(fields),
        play: true,
      }
    }, undefined, { 
      shallow: true 
    });
  };

  const onClickFullScreen = () => {
    setFullScreen((fullScreen) => !fullScreen);
  };

  const onClickExitFullScreen = () => {
    setFullScreen(false);
  };

  const fetchMoreGames = game.isDownloadGame
    ? DownloadGamesModel.searchGamesForGenre
    : OnlineGamesModel.searchGamesForGenre;

  // Listen to route change events to toggle game-in-play class
  useEffect(() => {
    if (view === 'play') {
      document.body.classList.add('game-in-play');
    } else {
      document.body.classList.remove('game-in-play');
    }

    const handler = (url: string) => {
      if (
        (
          (
            url.includes('&play')
          ) ||
          (
            isMobile(true) && 
            url.includes('gamelanding') && 
            game.pageLayout !== 'download' 
          )
        ) &&
        !url.includes('/archive')
      ) {
        setView('play');
        document.body.classList.add('game-in-play');
      } else {
        setView('about');
        document.body.classList.remove('game-in-play');
      }
    };
    handler(router.asPath);
    router.events.on('routeChangeComplete', handler);

    () => {
      router.events.off('routeChangeComplete', handler);
    };
  });

  // Display preroll ad when panel becomes visible
  useEffect(() => { 
    if (panel === 'ad') {
      (async () => {
        await displayPreroll('prerollAd');
        sendDisplayAdComplete();
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ panel ]);

  // Lock out regular users from SWU content
  useEffect(() => {
    if (!authState.ready) return;
    
    const today = getZonedDate();
    const gameDate = getZonedDate(
      dailyFieldsToDate(
        getFieldsFromDateQuery(router.query.date as string)[ 1 ]
      )
    );

    const isToday = (fields: DailyGameFields) => {
      const today = getZonedDate();
      const date = dailyFieldsToDate(fields);
      return today.toLocaleDateString('en-US') === date.toLocaleDateString('en-US');
    };

    const isOutOfRange = (
      (game.archive && game.archive.frequency === 'daily' && (
        // return false if gameDate is today or within the last two weeks
        gameDate.getTime() >= today.getTime() ||
        gameDate.getTime() <= today.setDate(today.getDate() - 14)
      )) || 
      (game.archive && game.archive.frequency === 'weekly' && (
        // return false if gameDate is today or within the last 14 weeks
        gameDate.getTime() >= today.getTime() ||
        gameDate.getTime() <= today.setDate(today.getDate() - 14 * 7)
      ))
    );

    if (
      (
        props.game.isBonusGame &&
        !authState.user?.isPremiumUser 
      ) || (
        !isToday(fields) &&
        !authState.user?.isPremiumUser && 
        isOutOfRange
      )
    ) {
      router.replace('/unlimited');
      return;
    }

    // if date is in next month, then redirect to home page
    if (gameDate.getMonth() > getZonedDate().getMonth()) {
      router.replace('/');
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState.ready, props.game, router.query.date ]);

  // Full screen mode
  useEffect(() => {
    if (isFullScreen) {
      document.body.classList.add('game-is-fullscreen');
    } else {
      document.body.classList.remove('game-is-fullscreen');
    }

    const handler = () => {
      document.body.classList.remove('game-is-fullscreen');
    };
    router.events.on('routeChangeComplete', handler);

    () => {
      router.events.off('routeChangeComplete', handler);
    };
  }, [ isFullScreen, router ]);

  const isStandardLayout =
    game.pageLayout === 'online' || game.pageLayout === 'download';

  const inlinePlayerProps = {
    width: undefined,
    height: undefined,
    fluid: false,
    spacer: undefined,
  };
  if (game.embed.width) {
    inlinePlayerProps.width = game.embed.width;
    inlinePlayerProps.height = game.embed.height;
  } else {
    inlinePlayerProps.fluid = true;
    inlinePlayerProps.spacer = ASPECT_4_3;
  }

  // Alternate game suggestion
  const [ alternateGame, setAlternateGame ] = useState<ExtendedGameData>(null);
  
  const fetchAlternateGame = async (url: string) => {
    const alternateGame = await GamesModel.getRandomGame(url);
    setAlternateGame(alternateGame);
  };

  const currentGameRef = useRef<string | null>(null);
  useEffect(() => {
    if (game.uid === currentGameRef.current) {
      return;
    }
    currentGameRef.current = game.uid;

    if (game.displayAlternateSuggestion) {
      if (game.alternateSuggestionGame) {
        setAlternateGame(game.alternateSuggestionGame);
      } else {
        fetchAlternateGame(game.randomGameSuggestionUrl);
      }
    } else {
      setAlternateGame(null);
    }
  }, [ game ]);

  const [ heroFullWide, setHeroFullWide ] = useState(false);
  useEffect(() => {
    // @ts-ignore
    if (!window.decoyScriptLoaded || authState.user?.isPremiumUser) {
      setHeroFullWide(true);
    } else {
      setHeroFullWide(false);
    }
  }, [ authState ]);

  useEffect(() => {
    (async () => {
      if (!authState.ready || !authState.user) return;
      const swagUser = await SwagModel.getSwagUser();
      setSwagUser(swagUser);
    })();
  }, [ authState.ready, authState.user ]);

  useEffect(() => {
    if (!authState.ready || !authState?.user?.uid) return;

    dispatchRecentGames(recentlyPlayedActions.addGame({
      userId: authState.user.uid,
      game: props.game
    }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState.ready, authState?.user?.uid, props.game ]);  

  return (
    <DailyGameLayout 
      className={className} 
      key={game.uid}
      pageName={(
        game.pageLayout === 'download'
          ? 'game_modern_download'
          : game.pageLayout === 'online'
            ? 'game_modern_online'
            : 'game_modern_daily'
      )}
      pageEventSettings={{
        gameTitle: game.title,
        gameId: game.uid,
      }}
    >

      {/* Meta information */}
      <Meta 
        {...metaProps}
      />

      {/* Modals */}
      <ShareModal 
        gameTitle={game.title}
        gameMediaUrl={game.href}
      />

      {/* Debug Helpers */}
      <input type='hidden' id='gameId' value={game.uid} />

      {
        If(!authState.user?.isPremiumUser, () => (
          <PlaywireCornerVideo options={{ breakpointVisibleAt: game.pageLayout === 'jigsaw' ? 1366 : 0 }} />
        )).EndIf()
      }

      {
        If(view === 'about', () => (
          /* 
           * View: About
           */
          <div className='GamePage__Container'>
            {
              If(!authState.user?.isPremiumUser, () => (
                <aside className='GamePage__Container__Aside'>
                  <PlaywireAside useAdFallback useVideoPlaceholder>
                    <PlaywireUnit unit='med_rect_atf' />
                  </PlaywireAside>
                </aside>  
              )).EndIf()
            }
            <div className='GamePage__Container__Content'>
              <Flex fit directionColumn>
                {/* Breadcrumbs */}
                {
                  If(isStandardLayout, () => (
                    <section className='--padMd0__y'>
                      <Container>
                        <Block>
                          <nav>
                            <ul className='GamePage__Breadcrumbs'>
                              <li>
                                {
                                  If(game.pageLayout === 'download', () => (
                                    <Link href={{
                                      pathname: '/search/all-games',
                                      query: { 'content_type[]': 'paid_game' }
                                    }}>
                                      <a>All Downloadable Games</a>
                                    </Link>
                                  )).Else(() => (
                                    <Link href='/search/all-games'>
                                      <a>All Online Games</a>
                                    </Link>
                                  )).EndIf()
                                }
                              </li>
                              <li> 
                                <Link href={genreBreadcrumbs[ 0 ].href}>
                                  <a>{genreBreadcrumbs[ 0 ].text}</a>
                                </Link>
                              </li>
                              <li>
                                <Link href={props.game.href}>
                                  <a>{props.game.title}</a>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </Block>
                      </Container>
                    </section>
                  )).EndIf()
                }

                {/* Hero */}
                <section>
                  {
                    If(alternateGame, () => (
                      <div className='--marMd0__b'>
                        <DiscontinuedGame 
                          game={alternateGame}
                        />
                      </div>
                    )).ElseIf(game.pageLayout === 'daily' || game.pageLayout === 'jigsaw', () => (
                      <DailyHero>
                        <PerDevice useDisplay>
                          <Slot name='mobile'>
                            <MobileHeroGameTile 
                              game={game} 
                              priority 
                              color='purple'
                              onClick={onClickPlayGame}
                            />
                          </Slot>
                          <Slot name='desktop'>
                            <HeroDailyGameTileContainer>
                              <HeroPlayGameTile 
                                game={game} 
                                today={today}
                                isBonusGame={game.isBonusGame}
                                onClick={onClickPlayGame}
                                priority 
                                isFullWidth={heroFullWide}
                              />
                            </HeroDailyGameTileContainer>
                          </Slot>
                        </PerDevice>
                      </DailyHero>
                    )).ElseIf(game.pageLayout === 'download', () => (
                      <Container>
                        <DownloadGameTile
                          priority
                          game={props.game}
                        />
                        <div className='--marMd0__t' />
                      </Container>
                    )).Else(() => (
                      <DailyHeroEmpty>
                        <Container>
                          <GamePlayer 
                            autoPlay={'afterInterstitial'}
                            previewImage={'/images/gamePlayerPlaceholder.png'} 
                            gameSrc={gameSrc} 
                            onClickPlay={onShowPrecontent} 
                            embedTag='iframe'
                            isFlash={game.embed.isFlash}
                            supportsMobile={{ portrait: true, landscape: true }}
                            onClickExitFullScreen={onClickExitFullScreen}
                            fullScreen={isFullScreen}
                            {...inlinePlayerProps}
                          >
                            <Slot name='playButton'>&nbsp;</Slot>
                            <Slot name='interstitial'>
                              <div id='playwire_precontent_ad' />
                            </Slot>
                          </GamePlayer>
                        </Container>
                      </DailyHeroEmpty>

                    )).EndIf()
                  }
                </section>

                <section className='GamePage__AboutSection'>
                  <Container>
                    {
                      If(!authState.user?.isPremiumUser, () => (
                        <Block>
                          <PlaywireUnit unit='leaderboard_atf' />
                        </Block>
                      )).EndIf()
                    }

                    {/* Archives/Upsell */}
                    {
                      If(game.archive, () => (
                        <>
                          <Block>
                            {
                              If(!authState.user || !authState.user.isPremiumUser, () => (
                                <>
                                  {
                                    If(game.archive.display === 'picture', () => (
                                      <>
                                        {
                                          If(game.archive.frequency === 'daily', () => (
                                            <DailyArchiveUpsellTile game={props.game} />
                                          )).Else(() => (
                                            <WeeklyArchiveUpsellTile game={props.game} />
                                          )).EndIf()
                                        }
                                      </>
                                    )).Else(() => (
                                      <DailyGameUpsellTile game={props.game} />
                                    )).EndIf()
                                  }
                                </>
                              )).ElseIf(swagUser, () => (
                                <>
                                  <Title h2 size5 className='--textCenter'>Archive</Title>
                                  {
                                    If(game.archive.frequency === 'weekly', () => (
                                      <WeeklyArchive
                                        game={game}
                                        swagUUID={props.swagUUID}
                                        entityID={swagUser._id}
                                      />
                                    )).Else(() => (
                                      <DailyArchive
                                        game={game}
                                        swagUUID={props.swagUUID}
                                        entityID={swagUser._id}
                                      />
                                    )).EndIf()
                                  }
                                  <Flex justifyCenter className='--marMd0__t'>
                                    <ButtonLink href={`${game.href}/archive`} rounded medium secondary>
                                      View More
                                    </ButtonLink>
                                  </Flex>
                                </>
                              )).EndIf()
                            }
                          </Block>
                        </>
                      )).EndIf()
                    }

                    {
                      If(game.bonusGameUrl && !game.isBonusGame && authState.user?.isPremiumUser, () => (
                        <Block>
                          <BonusGameTile game={game} />
                        </Block>
                      )).EndIf()
                    }

                    {/* Leaderboards */}
                    {
                      If(game.hasLeaderboards, () => (
                        <>
                          <Block>
                            <Title h2 size5 className='--textCenter'>Leaderboards</Title>
                            <LeaderboardContainer 
                              game={game}
                              onClickPlay={onClickPlayGame}
                              isLoggedIn={authState.ready && !!authState.user}
                            />
                          </Block>
                        </>
                      )).EndIf()
                    }

                    {/* Discussion */}
                    {
                      If(game.hasChatEnabled, () => (
                        <>
                          <Block>
                            <Title h2 size5 className='--textCenter'>Discussion</Title>
                            <DiscussionContainer game={game} />
                          </Block>
                        </>
                      )).EndIf()
                    }

                    {/* Game Information (Daily Games) */}
                    {
                      If(!isStandardLayout, () => (
                        <>
                          <Block>
                            <GameRequirementsTile 
                              game={props.game}
                            />  
                          </Block>
                        </>
                      )).EndIf()
                    }

                    {/* Online/Download Upsell */}
                    {
                      If(isStandardLayout, () => (
                        <>
                          {
                            If(!authState.user?.isPremiumUser, () => (
                              <Block>
                                <PerDevice>
                                  <Slot name='desktop'>
                                    <DownloadsUpsellTile eventLocation='mid_page_upsell' />
                                  </Slot>
                                  <Slot name='mobile'>
                                    <ArchivesUpsellTile eventLocation='mid_page_upsell' />
                                  </Slot>
                                </PerDevice>                     
                              </Block>
                            )).EndIf()
                          }
                          <Block>
                            <GameRequirementsTile 
                              game={props.game}
                            />                        
                          </Block>
                        </>
                      )).EndIf()
                    }

                    {/* More Games Like This */}
                    {
                      If(game.genres.length > 0, () => (
                        <>
                          {
                            If(!authState.user?.isPremiumUser, () => (
                              <Block>
                                <PlaywireUnit unit='leaderboard_btf' />
                              </Block>
                            )).EndIf()
                          }
                          <Block>
                            {
                              If(game.pageLayout === 'daily', () => (
                                <>
                                  <Title h2 size5 className='--textCenter'>More Games Like This</Title>
                                  <CompactDailyGameTileContainer>
                                    {
                                      For(props.initialMoreGames.slice(0, 3), (game, index: number) => (
                                        <DailyGameTile
                                          key={index} 
                                          game={game} 
                                          color={game.highlightColor}
                                          description={game.descriptionMedium}
                                          priority
                                          buttons={[
                                            { action: 'play', label: 'Play' },
                                            { action: 'archive', label: 'Archive' }
                                          ]}
                                        />
                                      ))
                                    }
                                  </CompactDailyGameTileContainer>
                                </>
                              )).Else(() => (
                                <DailyGameGenreGrid
                                  genre={props.genre}
                                  isDownloadGame={game.isDownloadGame}
                                  initialGames={props.initialMoreGames} 
                                  initialSortBy={props.moreGamesCriteria.sortBy} 
                                  fetchMoreGames={fetchMoreGames}
                                >
                                  <Slot name='title'>More Games Like This</Slot>
                                </DailyGameGenreGrid>
                              )).EndIf()
                            }
                          </Block>
                        </>
                      )).EndIf()
                    }

                  </Container>
                </section>
              </Flex>
            </div>
            {
              If(!authState.user?.isPremiumUser, () => (
                <aside className='GamePage__Container__Aside'>
                  <PlaywireAside stickToLeft>
                    <PlaywireUnit unit='med_rect_btf' />
                    <PlaywireUnit unit='med_rect_btf' />
                  </PlaywireAside>
                </aside>  
              )).EndIf()
            }
          </div>
        )).ElseIf(view === 'play', () => (
          /* 
           * View: Play
           */
          <Flex fit>
            <Flex fit directionColumn>

              {/* Game Player Header */}
              <Flex>
                <GamePlayerHeader
                  date={today.toISOString()}
                  hideDate={game.pageLayout === 'online'}
                  onClickFullScreen={onClickFullScreen}
                  game={game}
                />
              </Flex>

              {/* Game Player */}
              <Flex fit style={{ position: 'relative' }}>
                <GamePlayer 
                  autoPlay={true}
                  previewImage={'/images/gamePlayerPlaceholder.png'} 
                  gameSrc={gameSrc} 
                  onClickPlay={onShowGame} 
                  embedTag='iframe'
                  fluid={true}
                  supportsMobile={{ portrait: true, landscape: true }}
                  onClickExitFullScreen={onClickExitFullScreen}
                  fullScreen={isFullScreen}
                >
                  <Slot name='playButton'>&nbsp;</Slot>
                  <Slot name='interstitial'>&nbsp;</Slot>
                </GamePlayer>
              </Flex>

              {/* Panels */}
              <div className='GamePage__PlayPanels'>
                {
                  If(panel === 'ad', () => (
                    <div className='GamePage__Panel'>
                      <div id='prerollAd' />
                    </div>
                  )).EndIf()
                }
              </div>
            </Flex>
            {
              If(!authState.user?.isPremiumUser, () => (
                <Flex className='GamePage__ColumnAside'>
                  <PlaywireAside useAdFallback useVideoPlaceholder>
                    <PlaywireUnit unit='med_rect_atf' />
                  </PlaywireAside>
                </Flex>
              )).EndIf()
            }
          </Flex>
        )).EndIf()
      }

      {
        If(view !== 'play' && (game.pageLayout === 'daily' || game.pageLayout === 'jigsaw'), () => (
          <div 
            className={`GamePage__SmallDeviceOverlay --${game.highlightColor}`} 
          >
            <Flex 
              alignCenter justifyCenter tall
            >
              <Flex 
                center directionColumn alignCenter 
                className='GamePage__SmallDeviceOverlay__Inner' 
              >
                <img
                  src={game.mobileIcon}
                  alt=''
                />
                <h2>{game.title}</h2>
                <Button onClick={onClickPlayGame} fullWidth rounded>Play</Button>
                <ButtonLink href={game.href + '/archive'} fullWidth rounded>Archive</ButtonLink>
              </Flex>
            </Flex>
          </div>
        )).EndIf()
      }

    </DailyGameLayout>
  );
});

export async function getServerSideProps (context: NextPageContext) {
  const slug = context.query.slug as string;

  // Get game data
  let game: FullGameData;
  let swagUUID: string;
  try {
    game = await GamesModel.getBySlug('gamelanding/' + slug, context.req.headers.cookie);
    swagUUID = await SwagModel.getGameId(slug) ?? null;
  } catch (err) {
    return {
      notFound: true,
    };
  }

  // Get game genre and initial more games
  const genre = game.isDownloadGame
    ? downloadGenres.find((genre) => genre.uid === game.genres[ 0 ])
    : onlineGenres.find((genre) => genre.uid === game.genres[ 0 ]);

  const moreGamesCriteria: GamesByTaxonomyCriteria = {
    genre, 
    sortBy: 'all',
  };

  let initialMoreGames;
  if (game.pageLayout === 'jigsaw' || game.pageLayout === 'daily') {
    initialMoreGames = await GamesModel.getRecommendedDailyGames(game.uid);
  } else {
    try {
      initialMoreGames = await fetchGamesByTaxonomyTerm({
        ...moreGamesCriteria,
        method: game.isDownloadGame
          ? DownloadGamesModel.searchGamesForGenre
          : OnlineGamesModel.searchGamesForGenre
      });
    } catch (err) {
      throw err;
    }
  }

  // Put props together
  const props: GamePageProps = {
    game,
    slug,
    genre,
    moreGamesCriteria,
    initialMoreGames,
    swagUUID
  };

  return {
    props,
  };
}
