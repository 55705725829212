import { IntrinsicProps } from 'react-commons';
import { createComponent } from 'react-commons';

import style from './index.module.scss';
import { useEffect, useState } from 'react';

interface CalendarIconProps extends IntrinsicProps {
  date: Date
}

export default createComponent<CalendarIconProps>('CalendarIcon', { style }, function CalendarIcon ({ className }, props) {
  // Format like "Feb"
  const [ month, setMonth ] = useState<string>('');
  // Format like "12"
  const day = props.date.getDate().toString();

  useEffect(() => {
    const month = props.date.toLocaleString('default', { month: 'short' });
    setMonth(month);
  }, [ props.date ]);

  return (
    <div className={className}>
      <header>
        {month}
      </header>
      <div>
        {day}
      </div>
    </div>
  );
});
