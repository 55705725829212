import { ButtonLink, createComponent, If, IntrinsicProps, Slot } from 'react-commons';

import { FullGameData } from '@/lib/drupal/models/Games';
import PerDevice from '@/components/PerDevice';
import { dateToDailyFields } from '@/lib/hooks/useDailyArchive';
import { getZonedDate } from '@/lib/util/zonedTime';
import { UpsellArchiveTile } from '@/components/gamePage/Archive';
import { GridUpsellPod } from '@/components/dailyUpsellPods/GridUpsellPod';
import { getArchiveItemHref } from '@/lib/util/archiveHelpers';
import { useWeeklyArchive } from '@/lib/hooks/useWeeklyArchive';

interface WeeklyArchiveUpsellTileProps extends IntrinsicProps {
  priority?: boolean
  game: FullGameData
  isPremiumUser?: boolean
}

const getGameNoun = (uid: string) => {
  switch(uid) {
  case '7966':
    return 'puzzle';
  case '7987':
    return 'snoop';
  case '7963':
    return 'diff';
  default:
    return 'game';
  }
};

export const WeeklyArchiveUpsellTile =
createComponent<WeeklyArchiveUpsellTileProps>('WeeklyArchiveUpsellTile', {  }, function WeeklyArchiveUpsellTile ({ className }, props) {

  const {
    rows
  } = useWeeklyArchive({
    archive: props.game.archive,
  });

  const items = rows.flat();

  const desktopItems = [];

  if(items.length > 0) {
    for (let i = 0; i < 6; i++) {
      const item = items[ i ];
  
      const href = props.isPremiumUser ? getArchiveItemHref(item.fields, props.game.keyword, i === 0) : `${props.game.href}/archive`;
  
      desktopItems.push(
        <UpsellArchiveTile 
          item={item} 
          contentUrl={props.game.archive.contentUrl} 
          kind={props.game.isBonusGame ? 'bonus' : 'daily'} 
          hasPicture={props.game.archive.display === 'picture'}
          href={href}
        />
      );
    }
  } else {
    desktopItems.push(<></>);
  }

  return (
    <GridUpsellPod 
      backgroundColor={props.game.highlightColor} 
      rightText={props.isPremiumUser ? `With your subscription, you have access to more ${getGameNoun(props.game.uid)}s!` : `Play more ${getGameNoun(props.game.uid)}s from the archive with a subscription!`}
      items={desktopItems}
      mobileItem={desktopItems[ 1 ]} 
    >
      <Slot name='leftText'>
        <PerDevice useDisplay>
          <Slot name='mobile'>
            <h4 className='--marMd0__b'><strong>{props.game.title}</strong></h4>
            <p>{props.game.shortDescription}</p>
          </Slot>
          <Slot name='desktop'>
            <p>
              With {props.isPremiumUser ? 'your' : 'a'} subscription you can gain access to the entire catalog of daily game archives. From Daily Jigsaw to Daily Type, we’ve got you covered.
            </p>
          </Slot>
        </PerDevice>
      </Slot>
      <Slot name='leftButton'>
        <PerDevice useDisplay>
          <Slot name='mobile'>
            <ButtonLink href={`${props.game.href}`} rounded fullWidth medium className='--alignSelfFlexStart'>Play</ButtonLink>
          </Slot>
          <Slot name='desktop'>
            {
              If(props.isPremiumUser, () => (
                <ButtonLink href={`${props.game.href}/archive`} rounded medium className='--alignSelfFlexStart'>View Archive</ButtonLink>
              )).Else(() => (
                <ButtonLink href='/unlimited' rounded medium className='--alignSelfFlexStart'>Subscribe</ButtonLink>
              )).EndIf()
            }
          </Slot>
        </PerDevice>
      </Slot>
      <Slot name='rightButton'>
        {
          If(props.isPremiumUser, () => (
            <ButtonLink href={`${props.game.href}/archive`} rounded small>View Archive</ButtonLink>
          )).Else(() => (
            <ButtonLink rounded small href='/unlimited'>Subscribe</ButtonLink>
          )).EndIf()
        }
      </Slot>
    </GridUpsellPod>
  );
});
