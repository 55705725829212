import Image from 'next/image';
import { Button, ButtonLink, createComponent, Flex, For, If, IntrinsicProps, Slot } from 'react-commons';

import style from './index.module.scss';
import Link from 'next/link';
import GamesModel, { FullGameData } from '@/lib/drupal/models/Games';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import PerDevice from '../PerDevice';
import { dateToDailyFields, useCurrentDay } from '@/lib/hooks/useDailyArchive';
import { getZonedDate } from '@/lib/util/zonedTime';
import { getThumbnail } from '@/components/DailyArchive';
import { format } from 'date-fns';
import { ArchiveTile, getHref } from '@/components/gamePage/Archive';
import { useEffect, useState } from 'react';

interface BonusGameTileProps extends IntrinsicProps {
  game: FullGameData
}

function gameTileClassName (className: string, props: BonusGameTileProps) {

  const colorName = props.game.highlightColor ?? '';

  return className + (
    colorName 
      ? ' BonusGameTile--color BonusGameTile--color--' + colorName
      : ''
  );
}

export default
createComponent<BonusGameTileProps>('BonusGameTile', { style }, function BonusGameTile ({ className }, props) {
  const [ fields, today ] = useCurrentDay();

  const [ bonusGame, setBonusGame ] = useState<FullGameData | null>(null);
  useEffect(() => {
    (async () => {
      const slug = `/gamelanding/${props.game.bonusGameUrl.split('/').pop()}`;
      const bonusGame = await GamesModel.getBySlug(slug);
      setBonusGame(bonusGame);
    })();
  }, [ props.game ]);
  const bonusThumbnail = bonusGame && props.game.archive.display === 'picture' 
    ? (
      bonusGame.archive.frequency === 'weekly'
        ? getThumbnail(fields, bonusGame.archive.contentUrl)
        : getThumbnail(fields, bonusGame.archive.contentUrl, 'bonus')
    )
    : props.game.featuredImg;

  const tomorrowsDate = (() => {
    const date = getZonedDate(today);
    date.setDate(date.getDate() + 1);
    return dateToDailyFields(date);
  })();
  const tomorrowsUrl = getHref(tomorrowsDate, props.game.href.split('/').pop(), false);
  const tomorrowsThumbnail = props.game.archive.display === 'picture'
    ? getThumbnail(tomorrowsDate, props.game.archive.contentUrl, 'daily')
    : props.game.featuredImg;

  return (
    <div className={gameTileClassName(className, props)}>
      <section>
        <div className='BonusGameTile--title'>
          <h3>Bonus Games</h3>
        </div>
        <p className='p'>
          Your subscription gives you access to more games! You can play more from the archive and access tomorrow's puzzle today!
        </p>
        <br />
        <ButtonLink rounded href={props.game.href + '/archive'}>
          View Full Archive
        </ButtonLink>
      </section>
      <section>
        <Flex container alignCenter justifyCenter>
          <Link href={tomorrowsUrl}>
            <a>
              <figure>
                <img
                  src={tomorrowsThumbnail}
                  alt={'Tomorrow\'s Puzzle'}
                />
              </figure>
              <span>Tomorrow's Puzzle</span>
            </a>
          </Link>
          <Link href={props.game.bonusGameUrl}>
            <a>
              <figure>
                {
                  If(bonusGame?.archive?.contentUrl, () => (
                    <img
                      src={bonusThumbnail}
                      alt={'Bonus Puzzle'}
                    />
                  )).Else(() => (
                    <img
                      src={props.game.featuredImg}
                      alt={'Bonus Puzzle'}
                    />
                  )).EndIf()
                }
              </figure>
              <span>Bonus Puzzle</span>
            </a>
          </Link>
        </Flex>
      </section>
    </div>
  );
});

export const BonusGameTileUpsell =
createComponent<BonusGameTileProps>('BonusGameTile', { style }, function BonusGameTile ({ className }, props) {
  const items = [];
  for (let i = 0; i < 6; i++) {
    const date = getZonedDate();
    date.setDate(date.getDate() - i);
    const fields = dateToDailyFields(date);

    items.push({
      date: fields,
      label: format(date, 'MMMM do'),
      href: getHref(
        fields,
        props.game.href.split('/').pop(),
        i === 0,
      ),
      thumbnailSrc: getThumbnail(fields, props.game.archive.contentUrl, 'daily'),
    });
  }

  return (
    <div className={className + ' BonusGameTile--upsell'}>
      <section>
        <Flex alignCenter className='BonusGameTile--title'>
          <img 
            src='/images/shockwaveUnlimited.svg' 
            alt='Shockwave Unlimited'
            width='148'
            height='52'
          />
        </Flex>
        <div>
          <p>With a subscription you can gain access to the entire catalog of daily game archives. From Daily Jigsaw to Daily Type, we've got you covered.</p>
        </div>
        <Flex expand />
        <Button rounded medium className='--alignSelfFlexStart'>Subscribe</Button>
      </section>
      <section>
        <Flex wide tall directionColumn>
          <h4 className='--textCenter'>Play more Puzzles from the archive with a subscription!</h4>
          <Flex container directionColumn alignCenter>
            <PerDevice useDisplay>
              <Slot name='desktop'>
                <Flex tag='ul' fill gap0 wrap justifyCenter alignCenter>
                  {
                    If(props.game.archive.display === 'list', () => (
                      <>
                        {
                          For(items, (item, i) => (
                            <li key={i}>
                              <ArchiveTile 
                                item={{ fields: item.date }} 
                                contentUrl={props.game.archive.contentUrl} 
                                slug={props.game.href.split('/').pop()} 
                                kind={props.game.isBonusGame ? 'bonus' : 'daily'} 
                                state={'notStarted'}     
                                short
                              />
                            </li>
                          ))
                        }
                      </>
                    )).Else(() => (
                      <>
                        {
                          For(items, (item, i) => (
                            <li key={i}>
                              <Link href={item.href}>
                                <a>
                                  <Image 
                                    src={item.thumbnailSrc}
                                    width='111'
                                    height='65'
                                    alt={props.game.title + ' archive thumbnail'}
                                  />
                                  <span>{item.label}</span>
                                </a>
                              </Link>
                            </li>
                          ))
                        }
                      </>
                    )).EndIf()
                  }
                </Flex>
              </Slot>
              <Slot name='mobile'>
                <Link href='/'>
                  <a>
                    <Image 
                      src={items[ 1 ].thumbnailSrc}
                      width='111'
                      height='65'
                      alt={props.game.title + ' archive thumbnail'}
                    />
                  </a>
                </Link>
                <p>Yesterday's Puzzle</p>
                <ButtonLink rounded small href='/unlimited'>Subscribe</ButtonLink>
              </Slot>
            </PerDevice>
          </Flex>
        </Flex>
      </section>
    </div>
  );
});
