import { useState } from 'react';
import { Button, createComponent, For, IconFA, IntrinsicProps, Modal, ModalCard, useModal } from 'react-commons';

import style from './index.module.scss';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface ImageCarouselProps extends IntrinsicProps {
  images: string[]
  initialIndex?: number
  alt?: string
  onImageClick?: (index: number) => void
}

const Carousel = createComponent<ImageCarouselProps>('ImageCarousel__Carousel', { style }, function ImageCarousel__Carousel ({ className }, props) {
  const [ curIndex, setCurIndex ] = useState(props.initialIndex || 0);

  const next = () => {
    let nextIndex = curIndex + 1;
    if (nextIndex >= props.images.length) {
      nextIndex = 0;
    }
    setCurIndex(nextIndex);
  };

  const prev = () => {
    let prevIndex = curIndex - 1;
    if (prevIndex < 0) {
      prevIndex = props.images.length - 1;
      return;
    }
    setCurIndex(prevIndex);
  };

  const transformOffset = -100 * curIndex;
  
  return (
    <div className={className}>
      <div 
        className='ImageCarousel__Inner' 
        style={{ transform: `translateX(${transformOffset}%)` }}
      >
        {
          For(props.images, (image, index: number) => (
            <div key={index} className='ImageCarousel__Item'>
              <img 
                src={image} 
                alt={props.alt + ' image ' + index} 
                onClick={() => props.onImageClick?.(index)}
              />
            </div>
          ))
        }
      </div>
      <img 
        className='ImageCarousel__Spacer'
        src={props.images[ 0 ]} aria-hidden alt='' 
      />
      <div className='ImageCarousel__Controls'>
        <Button primary onClick={() => prev()}>
          <IconFA icon={faChevronLeft} />
        </Button>
        <Button primary onClick={() => next()}>
          <IconFA icon={faChevronRight} />
        </Button>
      </div>
    </div>
  );
});

const CarouselModal =  createComponent<ImageCarouselProps>('ImageCarousel__Modal', { style }, ({}, props) => {
  const { currentModal } = useModal();
  const index = (currentModal?.data as any)?.index || 0;

  return (
    <Modal className='ImageCarousel__Modal' name={'imageCarousel'}>
      <ModalCard>
        <Carousel 
          images={props.images} 
          alt={props.alt} 
          initialIndex={index}
        />
      </ModalCard>
    </Modal>
  );
});

export default createComponent<ImageCarouselProps>('ImageCarousel', { style }, ({}, props) => {
  const { openModal } = useModal();

  const openCarouselModal = (index: number) => {
    openModal('imageCarousel', {}, { index });
  };
  
  return (
    <>
      <Carousel 
        images={props.images} 
        alt={props.alt} 
        onImageClick={openCarouselModal}
      />
      <CarouselModal 
        images={props.images} 
        alt={props.alt} 
      />
    </>
  );
});
