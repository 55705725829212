import axios from '@/lib/drupal/api/axiosInstance';
import GamesModel, { DrupalGameData, ExtendedGameData, FeaturedGameData, GameData } from '@/lib/drupal/models/Games';
import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import { SearchCriteria } from '@/lib/hooks/useGamesByCriteria';
import { GamesByTaxonomyCriteria } from '@/lib/hooks/useGamesByTaxonomy';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from 'react-commons';
import { pagingQueryString } from '@/lib/util/pagingQueryString';
import { PagerData, formatPagerData } from '@/lib/util/pager';
import { decodeHtmlEntities } from '@/lib/util/decodeHtmlEntities';

export default class DownloadGamesModel {
  // #region All Genres

  static async searchAllGames (limit: number, offset: number, criteria: Omit<SearchCriteria, 'method'>, sortOrder: 'ASC' | 'DESC' = 'ASC'): Promise<{ games: ExtendedGameData[], pager: PagerData }> {
    let response: AxiosResponse;
    const sortBy = criteria.sortBy.replace('-', '');

    try {
      if (criteria.genre) {
        response = await axios.get(
          '/api/download/all-games/block/all-shockwave-games?' + 
          pagingQueryString(limit, offset) +
          `&tid=${criteria.genre}` +
          `&sort_by=${sortBy}` +
          `&sort_order=${sortOrder}`
        );
      } else {
        response = await axios.get(
          '/api/download/all-games/block/all-shockwave-games?' + 
          pagingQueryString(limit, offset) +
          `&sort_by=${sortBy}` +
          `&sort_order=${sortOrder}`
        );
      }
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent('Get All Online Games', false, error);
      throw error;
    }

    return {
      games: (response.data.rows || response.data).map((game: DrupalGameData) => GamesModel.transformExtended(game)), // TODO: fix when drupal response gets pager
      pager: formatPagerData(response.data.pager || { total_items: 0 })
    };
  }

  static async searchNewGames (limit: number, offset: number, criteria: Omit<SearchCriteria, 'method'>): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/download/new-games/block/new-releases?' + 
        pagingQueryString(limit, offset) +
        `&sort_by=${criteria.sortBy}` +
        `&tid=${criteria.genre}`
      );
    } catch (error) {
      logErrorEvent('Get New Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  // #endregion



  // #region Per Genre

  static async searchGamesForGenre (limit: number, offset: number, criteria: Omit<GamesByTaxonomyCriteria, 'method'>): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/common/download/block/games-by-taxonomy/${criteria.genre.uid}?` +
        pagingQueryString(limit, offset) +
        `&sort_by=${criteria.sortBy}`
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Download Games (${criteria.genre.name})`, false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getTopGamesForGenre (genre: TaxonomyStub, limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/common/download/block/top-games/${genre.uid}?` +
        pagingQueryString(limit, offset)
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Top Download Games (${genre.name})`, false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transform(game));
  }

  static async getTopGames (limit: number, offset: number): Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/download/main-page/block/download-top-games?' +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Top Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformFeatured(game));
  }

  static async getSliderGamesForGenre (genre: TaxonomyStub, limit: number, offset: number): Promise<FeaturedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/sw/api/common/download/block/slider/${genre.uid}?` +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent(`Get Slider Download Games (${genre.name})`, false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transformFeatured(game));
  }

  static async getSliderGames (limit: number, offset: number): Promise<FeaturedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/sw/api/download/block/slider?' +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Slider Download Games', false, error);
      throw error;
    }

    return response.data.map((game: DrupalGameData) => GamesModel.transformFeatured(game));
  }

  static async getFeaturedGamesForGenre (genre: TaxonomyStub, limit: number, offset: number): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        `/api/common/download/block/featured-games/${genre.uid}?` +
        pagingQueryString(limit, offset)
      );
      // response = await mockGet([ mockGameSchema ], limit, offset);
    } catch (error) {
      logErrorEvent(`Get Featured Download Games (${genre.name})`, false, error);
      throw error;
    }

    return (response.data.rows || response.data).map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getFeaturedGames (limit: number, offset: number): Promise<ExtendedGameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/download/main-page/block/featured-download-games?' +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get Featured Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  static async getComingSoon ():
  Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/download/new-games/block/coming-soon'
      );
    } catch (error) {
      logErrorEvent('Get Coming Soon Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData & { description: string }) => ({
      ...GamesModel.transform(game),
      description: decodeHtmlEntities(game.field_short_description)
    }));
  }

  static async getNewReleases (limit: number, offset: number):
  Promise<GameData[]> {
    let response: AxiosResponse;
    try {
      response = await axios.get(
        '/api/download/new-games/block/new-releases?' +
        pagingQueryString(limit, offset)
      );
    } catch (error) {
      logErrorEvent('Get New Releases Download Games', false, error);
      throw error;
    }

    return response.data.rows.map((game: DrupalGameData) => GamesModel.transformExtended(game));
  }

  // #endregion
}
