import { captureEvent } from '@/lib/util/captureEvent';

export function displayPreroll (containerId) {
  return new Promise<void>((resolve, reject) => {
    resolve();
    // const YMPB: YMPB = (window as any).YMPB;

    // // @ts-ignore
    // if (window.decoyScriptLoaded === undefined) {
    //   reject(new Error('AdBlocker detected.'));
    //   return;
    // }
    
    // if (YMPB !== undefined) {
    //   try {
    //     if (window.location.href.includes('utm')) {
    //       captureEvent('ad_impression', { 
    //         tag_name: 'ad_impression_video',
    //         ad_unit: 'preroll',
    //       });
    //     }
        
    //     YMPB.preroll(containerId, resolve);
    //   } catch(e) {
    //     reject(e);
    //   }
    // } else {
    //   reject('Yolla library was not found on window');
    // }
  });
}
