import React from 'react';
import { Button, ButtonLink, createComponent, Dropdown, DropdownItem, Flex, For, IconFA, If, IntrinsicProps, Slot } from 'react-commons';
import Image from 'next/image';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { LeaderboardToolbarStateActionType, useLeaderboards } from '@/lib/hooks/useLeaderboards';
import { FullGameData } from '@/lib/drupal/models/Games';
import LeaderboardTable from '@/components/LeaderboardTable';

import { getZonedDate } from '@/lib/util/zonedTime';
import Link from 'next/link';
import { dateToDailyFields, useCurrentDay } from '@/lib/hooks/useDailyArchive';
import { dailyFieldsToDateKey, getHref, isToday } from '@/components/gamePage/Archive';
import { getJigsawThumbnail } from '@/components/DailyGame';
import CalendarIcon from '@/components/CalendarIcon';

import style from './index.module.scss';
import { getThumbnail } from '@/components/DailyArchive';
import { isSameMonth } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import SwagModel, { LEADERBOARD_PERIOD, UserBestData } from '@/lib/swag/models/Swag';

interface LeaderboardContainerProps extends IntrinsicProps {
  game: FullGameData
  isLoggedIn: boolean
  onClickPlay: () => void
}

export const LeaderboardContainer = createComponent<LeaderboardContainerProps>('LeaderboardContainer', { style }, function LeaderboardContainer ({ className }, props) {
  const contentUrl = props.game.archive?.display === 'picture'
    ? props.game.archive.contentUrl
    : null;

  const [ fields ] = useCurrentDay();
  const dateKey = dailyFieldsToDateKey(fields);
  
  return (
    <div className={className}>
      {
        If(props.game.archive, () => (
          <aside>
            <LeaderboardSelector
              game={props.game}
              slug={props.game.href.replace('/gamelanding/', '')}
              contentUrl={contentUrl}
              isJigsawGame={props.game.pageLayout === 'jigsaw'}
              onClickPlay={props.onClickPlay}
            />
          </aside>
        )).EndIf()
      }
      <div>
        <Leaderboard 
          key={props.game.keyword + '_' + dateKey}
          keyword={props.game.keyword}
          isLoggedIn={props.isLoggedIn}
          dailyLevelKey={props.game.dailyLevelKey}
        />
      </div>
    </div>
  );
});

interface LeaderboardSelectorProps extends IntrinsicProps {
  slug: string
  isJigsawGame: boolean
  contentUrl?: string
  game: FullGameData
  onClickPlay: () => void
}

export const LeaderboardSelector = createComponent<LeaderboardSelectorProps>('LeaderboardSelector', { style }, function LeaderboardSelector ({ className }, props) {
  const [ fields, today ] = useCurrentDay();
  const todayFormatted = today.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const prevDate = (() => {
    const date = getZonedDate(today);
    date.setDate(date.getDate() - 1);
    return dateToDailyFields(date);
  })();
  const prevHref = getHref(
    prevDate,
    props.slug,
    isToday(prevDate)
  );

  const nextDate = (() => {
    const date = getZonedDate(today);
    date.setDate(date.getDate() + 1);
    return dateToDailyFields(date);
  })();
  const nextHref = getHref(
    nextDate,
    props.slug,
    isToday(nextDate)
  );

  const thumbnailSrc = props.contentUrl
    ? (
      props.isJigsawGame
        ? getJigsawThumbnail(today, 0, props.slug === 'bonusjigsaws' ? 'bonus' : 'daily')
        : getThumbnail(fields, props.contentUrl, 'daily')
    )
    : '';

  const archiveStartDate = getZonedDate(props.game.archive.startDate);
  const isFirstMonth = 
    today.getMonth() === archiveStartDate.getMonth() && 
    today.getFullYear() === archiveStartDate.getFullYear() && 
    today.getDate() === archiveStartDate.getDate();
    
  const isCurrentMonth = 
    isSameMonth(today, getZonedDate()) && 
    today.getFullYear() === getZonedDate().getFullYear() &&
    today.getDate() === new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

  const [ userBest, setUserBest ] = useState<UserBestData>(null);
  const lastGameIdRef = useRef<string>(null);
  const lastDateKeyRef = useRef<string>(null);

  useEffect(() => {
    (async () => {
      if (
        lastGameIdRef.current === props.game.uid &&
        lastDateKeyRef.current === dailyFieldsToDateKey(fields)
      ) {
        return;
      }
      lastGameIdRef.current = props.game.uid;
      lastDateKeyRef.current = dailyFieldsToDateKey(fields);
      
      const gameId = await SwagModel.getGameId(props.game.keyword);
      const result = await SwagModel.getUserBestDaily(
        gameId, 
        dailyFieldsToDateKey(fields), 
        props.game.dailyLevelKey, 
      );
      setUserBest(result);
    })();
  }, [ fields, props.game ]);

  return (
    <div className={className}>
      <Flex alignCenter justifyCenter gap0 container directionColumn>
        <Flex alignCenter justifyCenter>
          <Flex gap0 container>
            {
              If(isFirstMonth, () => (
                <span data-disabled>&lt;</span>
              )).Else(() => (
                <Link href={prevHref}><span>&lt;</span></Link>
              )).EndIf()
            }
            <span>{todayFormatted}</span>
            {
              If(isCurrentMonth, () => (
                <span data-disabled>&gt;</span>
              )).Else(() => (
                <Link href={nextHref}><span>&gt;</span></Link>
              )).EndIf()
            }
          </Flex>
        </Flex>
        <Flex alignCenter justifyCenter>
          {
            If(props.contentUrl, () => (
              <Image
                src={thumbnailSrc}
                alt='Game Thumbnail'
                width={220}
                height={139}
              />
            )).Else(() => (
              <CalendarIcon 
                date={today} 
              />
            )).EndIf()
          }
        </Flex>
        <Flex alignCenter justifyCenter>
          {
            If(userBest && userBest.scorePosition != 0, () => (
              <p>
                <strong>
                  #{userBest.scorePosition} out of
                  #{userBest.totalScores}
                </strong>
              </p>
            )).Else(() => (
              <p>No scores submitted yet.</p>
            )).EndIf()
          }
        </Flex>
        <Flex alignCenter justifyCenter>
          <Button rounded medium onClick={props.onClickPlay}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Play&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </Flex>
      </Flex>
    </div>
  );
});

interface LeaderboardProps extends IntrinsicProps {
  keyword: string
  isLoggedIn: boolean
  dailyLevelKey?: string
}

export const Leaderboard = createComponent<LeaderboardProps>('Leaderboard', { style }, function Leaderboard ({ className }, props) {
  const {
    isLoading,
    error,
    tableData,
    toolbarState,
    updateToolbarState,
    retry,
  } = useLeaderboards(props.keyword, { 
    dailyLevelKey: props.dailyLevelKey
  });

  const currentGameModeLabel = toolbarState.gameModes.find((gameMode) => gameMode.key === toolbarState.currentGameMode)?.label;
  
  return (
    <div className={className}>
      {
        If(!props.isLoggedIn, () => (
          <div className='--marSm2__b'>
            <Flex alignCenter justifyCenter gap0>
              <p>
                Want to save your score and track your stats?
              </p>
              <ButtonLink href='/account/register' rounded small secondary>
                Create Account
              </ButtonLink>
            </Flex>
          </div>
        )).Else(() => (
          <Flex justifyCenter className='--marMd0__b'>
            <Dropdown right>
              <Slot name='trigger'>
                {
                  If(isLoading, () => (
                    <Button rounded primary small loading>
                      <span />
                      <IconFA icon={faChevronDown} small className='--marSm2__l' />
                    </Button>
                  )).Else(() => (
                    <Button rounded primary small>
                      <span>{currentGameModeLabel}</span>
                      <IconFA icon={faChevronDown} small className='--marSm2__l' />
                    </Button>
                  )).EndIf()
                }
              </Slot>
              <Slot name='content'>
                {
                  If(!isLoading, () => (
                    <>
                      {
                        For(toolbarState.gameModes, (gameMode, index) => (
                          <DropdownItem
                            key={gameMode.key + '_' + index}
                            onClick={() => updateToolbarState(LeaderboardToolbarStateActionType.SET_CURRENT_GAME_MODE, gameMode.key)}
                          >
                            {gameMode.label}
                          </DropdownItem>
                        ))
                      }
                    </>
                  )).EndIf()
                }
              </Slot>
            </Dropdown>
          </Flex>
        )).EndIf()
      }
      
      <LeaderboardTable
        isLoading={isLoading}
        error={error}
        tableData={tableData}
        retry={retry}
        compact
      />
    </div>
  );
});
