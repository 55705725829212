import { Button, Buttons, createComponent, Flex, IconFA, If, IntrinsicProps, useModal, useNotifications } from 'react-commons';

import style from './index.module.scss';
import { FullGameData } from '@/lib/drupal/models/Games';
import { faShareAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import axiosInstance from '@/lib/drupal/api/axiosInstance';
import UsersModel from '@/lib/drupal/models/Users';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { useRef, useState, useEffect } from 'react';
import FavoriteButton from '@/components/FavoriteButton';

interface GameRequirementsTileProps extends IntrinsicProps {
  game: FullGameData
}

export default
createComponent<GameRequirementsTileProps>('GameRequirementsTile', { style }, function GameRequirementsTile ({ className }, props) {
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const { login } = useAuthMethods();
  const [ authState ] = useAuthStore();
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ hasVoted, setHasVoted ] = useState(false);
  const { openModal } = useModal();

  const rateGame = async (rating: number) => {
    if (!authState.ready || !authState.user) {
      notify('You must be logged in to rate games.');
      login();
      return;
    }

    notify(<>Thanks for rating <strong>{props.game.title}</strong>!</>);
    setHasVoted(true);

    startLoading();
    try {
      await UsersModel.rateGame(props.game.uid, rating);
    } catch {
      notify('There was an error rating this game. Please try again later.', 'error');
      setHasVoted(false);
    }
    finishLoading();
  };

  useEffect(() => {
    if (hasVoted) return;
    if (!authState.ready || !authState.user) return;
    if (!axiosInstance.defaults.headers[ 'X-CSRF-Token' ]) return;

    (async () => {
      startLoading();
      try {
        const hasVoted = await UsersModel.hasRatedGame(props.game.uid, authState.user.uid);
        setHasVoted(hasVoted);
      } catch {
        setHasVoted(false);
      }
      finishLoading();
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.game.uid, authState, hasVoted ]);

  const ratingOptions = Array
    .from(Array(5).keys())
    .map((i) => (i + 1) * 20)
    .reverse();
    
  return (
    <div className={className}>
      {
        If(props.game.description && props.game.pageLayout !== 'daily', () => (
          <Flex className='--marMd0__b'>
            <div>
              <h4>Description</h4>
              <div dangerouslySetInnerHTML={{ __html: props.game.description }} />
            </div>
          </Flex>
        )).EndIf()
      }
      <Flex wide gap2 container collapseMobile>
        {
          If(props.game.requirements && props.game.isDownloadGame, () => (
            <Flex>
              <div>
                <h4>Minimum Requirements</h4>
                <div dangerouslySetInnerHTML={{ __html: props.game.requirements }} />
              </div>
            </Flex>
          )).EndIf()
        }
        {
          If(props.game.instructions, () => (
            <Flex fit>
              <div>
                <h4>Instructions</h4>
                <div dangerouslySetInnerHTML={{ __html: props.game.instructions }} />
              </div>
            </Flex>
          )).ElseIf(props.game.description, () => (
            <Flex fit>
              <div>
                <h4>Description</h4>
                <div dangerouslySetInnerHTML={{ __html: props.game.description }} />
              </div>
            </Flex>
          )).EndIf()
        }
        <Flex>
          <ul className='__Credits'>
            {
              If(props.game.credits, () => (
                <li dangerouslySetInnerHTML={{ __html: props.game.credits }} />
              )).EndIf()
            }
            <li className='__Rating'>
              <span>Rating: {parseFloat(props.game.rating).toFixed(1)}</span>
              <IconFA icon={faStar} />
            </li>
            {
              If(!hasVoted, () => (
                <li>
                  <span>Add Rating:</span>
                  <div className='__RatingsPicker'>
                    {
                      ratingOptions.map((rating, index) => (
                        <span 
                          key={index}
                          onClick={() => rateGame(rating)} 
                        >
                          <IconFA icon={faStarOutline} />
                          <IconFA icon={faStar} />
                        </span>
                      ))
                    }
                  </div>
                </li>
              )).EndIf()
            }
            <li>
              <Buttons>
                <Button onClick={() => openModal('shareGame')} medium rounded secondary>
                  <span>Share Game</span>
                  <IconFA icon={faShareAlt} />
                </Button>
                <FavoriteButton 
                  gameId={props.game.uid} 
                  gameTitle={props.game.title}
                  rounded
                />
              </Buttons>
            </li>
          </ul>
        </Flex>
      </Flex>
    </div>
  );
});
