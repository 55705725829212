import Image from 'next/image';
import { Button, ButtonLink, Buttons, createComponent, If, IntrinsicProps } from 'react-commons';

import { FullGameData } from '@/lib/drupal/models/Games';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import { useDetectOperatingSystem } from '@/lib/hooks/useDetectOs';
import GamepadIcon from '@/components/GamepadIcon';

import style from './index.module.scss';
import ImageCarousel from '@/components/ImageCarousel';

interface DownloadGameTileProps extends IntrinsicProps {
  priority?: boolean
  game: FullGameData
}

export default
createComponent<DownloadGameTileProps>('DownloadGameTile', { style }, function DownloadGameTile ({ className }, props) {
  const [ isPremiumUser ] = useIsPremiumUser();
  const os = useDetectOperatingSystem();

  return (
    <div className={className}>
      <figure>
        <ImageCarousel
          images={[
            props.game.featuredImg,
            ...props.game.billboardImg 
          ]}
          alt={props.game.title}
        />
      </figure>
      <section>
        <h3>{props.game.title}</h3>
        <div className='p' dangerouslySetInnerHTML={{ __html: props.game.descriptionShort }} />
        <Buttons>
          {
            If(isPremiumUser || props.game.hasTrial, () => (
              <>
                {
                  If(os !== 'Windows', () => (
                    <Button
                      medium 
                      rounded
                      primary
                      disabled
                    >
                      Download Game (Windows Only)
                    </Button>
                  )).ElseIf(isPremiumUser, () => (
                    <ButtonLink 
                      medium 
                      primary 
                      rounded
                      href={props.game.downloadUrl}
                    >
                      Download Now
                    </ButtonLink>
                  )).Else(() => (
                    <CaptureClickEvent
                      action='upsell'
                      location='game_panel'
                      href={'/unlimited'}
                      properties={() => ({
                        tag_name: 'free_download_upsell',
                        id: props.game.uid,
                        title: props.game.title,
                        view_node: props.game.href,
                      })}
                    >
                      <ButtonLink 
                        medium 
                        primary 
                        rounded
                        href={'/unlimited'}
                        className='free_download_upsell'
                      >
                        Free Download with Shockwave Unlimited
                      </ButtonLink>
                    </CaptureClickEvent>
                  )).EndIf()
                }
              </>
            )).EndIf()
          }
          {
            If(!isPremiumUser && os === 'Windows', () => (
              <CaptureClickEvent
                action='purchase_entry'
                location='download_game_panel'
                href={props.game.paidgameBuyUrl}
                properties={() => ({
                  tag_name: 'basketadd_download_buynow',
                  title: props.game.title,
                  id: props.game.uid,
                })}
              >
                <ButtonLink 
                  medium 
                  secondary 
                  rounded
                  href={props.game.paidgameBuyUrl}
                  className='basketadd_download_buynow'
                >
                  Buy Now ${props.game.paidgamePrice}
                </ButtonLink>
              </CaptureClickEvent>
            )).EndIf()
          }
          {
            If(!isPremiumUser && os === 'Windows' && props.game.hasTrial, () => (
              <CaptureClickEvent
                action='upsell'
                location='download_game_panel'
                href={props.game.downloadUrl}
                properties={() => ({
                  tag_name: 'upsell_download_trydemo',
                  title: props.game.title,
                  id: props.game.uid,
                })}
              >
                <ButtonLink
                  medium 
                  rounded
                  href={props.game.downloadUrl}
                  className='upsell_download_trydemo'
                >
                  Try Demo
                </ButtonLink>
              </CaptureClickEvent>
            )).EndIf()
          }
        </Buttons>
      </section>
    </div>
  );
});
